<template>
  <b-card
    title="จัดการโปรโมชั่น"
    no-body
  >
    <b-card
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="info"
                class="mr-1"
                @click="addnewPromotion()"
              >
                เพิ่มโปรโมชั่นใหม่
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
    <b-table
      ref="promotions"
      striped
      hover
      responsive
      class="position-relative"
      :busy.sync="isBusy"
      :per-page="tableQuery.page_size"
      :current-page="tableQuery.page"
      :items="fetchPromotions"
      :fields="fields"
    >
      <template #cell(active)="data">
        <b-badge :variant="{1:'light-success', 0:'light-primary'}[data.item.active]">
          {{ {1:'เปิดใช้งาน', 0:'ปิดการทำงาน'}[data.item.active] }}
        </b-badge>
      </template>
      <template #cell(btns)="data">
        <div style="text-align:center;">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-info"
            size="sm"
            @click="viewAdmin(data.item)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            แก้ไขข้อมูล
          </b-button>{{ ' ' }}
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-danger"
            size="sm"
            @click="deletePromotion(data.item)"
          >
            <feather-icon
              icon="MinusIcon"
              class="mr-50"
            />
            ลบ
          </b-button>
        </div>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="tableQuery.page_size"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="tableQuery.page"
          :total-rows="totalRows"
          :per-page="tableQuery.page_size"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BButton, BCardBody, BCol, BRow, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    // Cleave,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    // // vSelect,
    // flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      isBusy: false,
      pageOptions: [100, 200, 500, 1000],
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 100,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'id', label: 'รหัสโปรโมชั่น' },
        { key: 'active', label: 'เปิดใช้งาน' },
        { key: 'title', label: 'ชื่อโปรโมชั่น' },
        { key: 'event_type', label: 'ประเภท', formatter: val => (this.promotionEventMaps[val] || val) },
        { key: 'btns', label: 'ดำเนินการ' },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      promotionEventMaps: {},
    }
  },
  computed: {
    // getBankOptions() {
    //   return this.bankListOptions
    // },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.fetchMember()
  },
  methods: {
    searchMember() {
      this.$refs.staffadmin.refresh()
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.$refs.staffadmin.refresh()
    },
    deletePromotion(data) {
      this.$swal({
        title: 'ยืนยันการลบโปรโมชั่น?',
        text: `คุณกำลังจะยืนยันการลบโปรโมชั่น ${data.detail} ใช่ไหม การลบโปรโมชั่นจะลบยอดโปรที่ลูกค้าสะสมด้วย`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.delete(`promotions/${data.id}`)
        return { data: { success: false } }
      }).then(({ data: updateResult }) => {
        if (updateResult.success) {
          this.$swal({
            icon: 'success',
            title: 'ลบแอดมินเรียบร้อย',
            text: 'ยืนยันการลบแอดมินเรียบร้อย',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$refs.staffadmin.refresh()
        }
      }).catch(err => {
        this.$bvToast.toast(`เกิดข้อผิดพลาด | ${err}`, {
          variant: 'danger',
          title: 'ยืนยันการลบแอดมินเรียบร้อยไม่สำเร็จ',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
        })
      })
    },
    async fetchPromotions(ctx) {
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key].value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      const { data: { promotions, promotionEvents } } = await this.$http.get(`promotions${query !== '' ? (`?${query}`) : ''}`)
      this.items = promotions.data
      this.totalRows = promotions.total
      this.promotionEventMaps = promotionEvents
      return this.items
    },
    viewAdmin(data) {
      this.$router.push({ name: 'setting-promotion-detail', params: { promotionId: data.id } })
    },
    addnewPromotion() {
      this.$router.push({ name: 'setting-promotion-detail', params: { promotionId: 'add-new' } })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
